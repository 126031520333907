.card-service{
    width: 100%;
    background-color: #111;
    transition: all 0.3s ease;
}
.service-icon{
    font-size: 3.5em;
    color: #21618c;
}
.card-service:hover{
    width: 100%;
    background-color: #ebf5fb;
    transform: scale(1.05);
    color: #21618c;
}
/* .title-text{
    color: #fff;
} */
.card-service:hover .service-icon, .title-text, .title-desc{
    width: 100%;
    color: #21618c;
    font-size: 3.5em;
}