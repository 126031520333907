html {
  scroll-behavior: smooth !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  font-family: 'Noto Sans', sans-serif;
  overflow-x: hidden;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-links {
  color: #1A4D2E !important;
  text-decoration: none;
  padding: 0.1rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  text-decoration: none !important;
  font-family: "Noto Sans";
  font-size: 1.25rem;
  font-weight: 600;
}

.nav-links:hover {
  color: #FFB1B1 !important;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  height: 100%;
  border-bottom: 3px solid transparent;
  text-decoration: none !important;
  font-weight: 600;
}

/* .fa-code {
  margin-left: 1rem;
} */

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  /* background: #ffdd40; */
  background: #FFB1B1;
  text-decoration: none !important;
}

.nav-item .active {
  color: #FFB1B1 !important;
  font-family: "Noto Sans";
  font-size: 1.25rem !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  /* border: 1px solid #ffdd40; */
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: '1px solid #fff';
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1f5156;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #1f5156;
    border: none;
  }

  .nav-links {
    /* padding: 1.5rem; */
    width: 100%;
    display: table;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #ffdd40;
  }
}

.scroll-box {
  width: "100%";
  height: 45vh;
  background-color: #000;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.proj-button {
  width: 100%;
  height: 2.8em;
  border-radius: 5px;
  font-size: 15px;
  font-family: "Noto Sans";
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #292B2C;
  color: #fff;
  cursor: pointer;
}

.proj-button::before {
  content: '';
  width: 0;
  height: 2.8em;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #17acff 0%, #ff68f0 100%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}

.proj-button:hover::before {
  width: 100%;
}
.prod-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 15px;
  font-family: "Noto Sans";
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #292B2C;
  color: #fff;
  cursor: pointer;
}

.prod-card::before {
  content: '';
  width: 0;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #1c98da, #80aee5, #b6c6ee, #dfe1f6, #ffffff);
  transition: .5s ease;
  display: block;
  z-index: -1;
}

.prod-card:hover::before {
  width: 100%;
}

.lern-btn {
  width: 100%;
  height: 2.7em;
  font-family: "Noto Sans";
  background-color: transparent;
  border: 1.5px solid #ffffff50;
  color: #fff;
  border-radius: 5px;
  transition: .5s ease;
}
.lern-btn:hover {
  width: 100%;
  height: 2.7em;
  font-family: "Noto Sans";
  border: 1.5px solid #ff68f0;
  color: #ff68f0;
  border-radius: 5px;
  transition: .5s ease;
}

.linkedIn-icon{
  color: #ABB2B9;
  font-size: 1.5em;
  background-color: transparent;
  transition: color 0.3s ease, font-size 0.3s ease, background-color 0.3s ease;
}
.linkedIn-icon:hover{
  color: #fff;
  font-size: 1.5em;
  background-color: #3498DB;
}
.facebook-icon{
  color: #ABB2B9;
  font-size: 1.5em;
  background-color: transparent;
  transition: color 0.3s ease, font-size 0.3s ease, background-color 0.3s ease;
}
.facebook-icon:hover{
  color: #fff;
  font-size: 1.5em;
  background-color: #1877F2;
}
.insta-icon{
  color: #ABB2B9;
  font-size: 1.4em;
  background-color: transparent;
  transition: color 0.3s ease, font-size 0.3s ease, background-color 0.3s ease;
}
.insta-icon:hover{
  color: #fff;
  font-size: 1.4em;
  background: #d6249f; background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.input-box{
  background-color: #292B2C;
  width: 100%;
  outline: none;
  border-radius: 5px;
  color: #fff;
}

.main-div {
  overflow: hidden;
  position: relative;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.background-div {
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
}

.div-content {
  position: relative;
}
.scroll-to-top-button {
  position: fixed;
  bottom: 50px;
  right: 48px;
  z-index: 1000;
}
.service-back {
  width: 100%;
  /* height: 100vh; */
  /* background-image: linear-gradient(to top, #206ada, #798be3, #adafec, #ffffff, #ffffff); */
  background-image: linear-gradient(to bottom, #ffffff, #f0f1fb, #e8ebf4, #d2d9e6, #cbdff4, #adb8ee, #9fabe8, #96a1d4, #98a9fe, #7187f4, #687ff2, #000000);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  backdrop-filter: blur(5px)
}
.marquee-text {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  font-size: 25em;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #fff;
  opacity: 0.4;
  white-space: nowrap; 
  z-index: 0;
  align-items: center;
  text-align: center;
  /* backdrop-filter: blur(5px) */
}
.card-container {
  z-index: 1;
}
.service-card  {
  background-color: transparent;
  text-align: center;
  padding: 2em;
  margin: 1em;
  border-radius: 8px;
  border: none;
}

.service-card  img {
  width: 100%;
  object-fit: cover;
}

.service-card  p {
  font-size: 1.5em;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #fff;
}


section {
  min-height: 100vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0 !important;
}

#section_1 {
  background: #000000;
}

#section_2 {
  background: #000000;
}

#section_3 {
  background: #000000;
}

.animate-text {
  height: 46px;
  overflow: hidden;
  color: #fff;
}

.animate-text span{
    font-size: 40px;
    /* color: #0d540e; */
    font-weight: 700;
    line-height: 46px;
    display: none;
    font-family: "Noto Sans", sans-serif;
}

.animate-text span.text-in{
	display: block;
	animation: textIn .5s ease;
}
.animate-text span.text-out{
	animation: textOut 1s ease;
}
@keyframes textIn{
	0%{
		transform: translateY(100%);
	}
	100%{
		transform: translateY(0%);
	}
}
@keyframes textOut{
	0%{
		transform: translateY(0%);
	}
	100%{
		transform: translateY(-100%);

	}
}
.animation-element {
  opacity: 0;
}

/*animation element sliding left*/
.animation-element.slide-left {
  opacity: 0;
  transition: all 500ms linear;
  transform: translate3d(-100px, 0px, 0px);
}

.animation-element.slide-left.in-view {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.animation-element.slide-right {
  opacity: 0;
  transition: all 500ms linear;
  transform: translate3d(100px, 0, 0);
}

.animation-element.slide-right.in-view {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.animation-element.slide-up {
  opacity: 0;
  transition: all 500ms linear;
  transform: translate3d(0, 100px, 0);
}

.animation-element.slide-up.in-view {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.animation-element.slide-left.testimonial, .animation-element.slide-right.testimonial, .animation-element.slide-up.testimonial {
  margin: 0% 1.5% 3% 1.5%;
  /* background: #F5F5F5; */
  /* padding: 15px; */
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  /* border: solid 1px #EAEAEA; */
}
.animation-element.slide-left.testimonial:hover,
.animation-element.slide-left.testimonial:active{
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}


.animation-element.slide-left.testimonial .header{
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.animation-element.slide-left.testimonial .left{
  float: left;
  margin-right: 15px;
}
.animation-element.slide-left.testimonial .right{
  float: left;
}

.animation-element.slide-left.testimonial img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(51, 51, 51, 0.5);
}

.animation-element.slide-left.testimonial h3 {
  margin: 0px 0px 5px 0px;
}

.animation-element.slide-left.testimonial h4 {
  margin: 0px 0px 5px 0px;
}

.animation-element.slide-left.testimonial .content {
  float: left;
  width:100%;
  margin-bottom: 10px;
}

.animation-element.slide-left.testimonial .rating{}

.animation-element.slide-left.testimonial i {
  color: #aaa;
  margin-right: 5px;
}

/* ------------NABVAR START--------- */
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0rem;
  /* height: 100px; */
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px)
} 

.fas {
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
}

.list-nav-bar {
  list-style: none;
  /* text-transform: uppercase; */
  display: flex;
  gap: 20px;
}

.list-item a {
  cursor: pointer;
  font-size: 1.25rem;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-weight: 600;
  /* margin-left: 0.5rem; */
  /* letter-spacing: 0.1rem; */
}

.list-item a:hover {
  color: #a0a0a0;
}

.burger-menu {
  display: none;
}

.main-content {
  text-align: center;
  margin-top: 25vh;
}

.main-content h1 {
  color: #fff;
  font-size: 3.5rem;
}


@media screen and (max-width: 768px) {

  .list-item a {
      font-size: 0.875rem;
  }

  .logo {
      font-size: 0.875rem;
  }
}

@media screen and (max-width: 578px) {

  .list-item a {
      font-size: 1rem;

  }

  .list-nav-bar.active {
      right: 0;
  }

  .list-nav-bar {
      display: flex;
      position: fixed;
      right: -100%;
      top: 100px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      text-align: center;
      flex-direction: column;
      transition: 0.7s;
      gap: 18px;
      border-radius: 0px;
  }

  .burger-menu {
      display: block;
      cursor: pointer;
  }
}
/* ------------NABVAR END--------- */


img {
  display: block;
  padding: 0;
  margin: 0;
}

/* CLASS STYLES */
.main-section {
  padding: 70px 0;
  width: 80%;
  margin: 0 auto;
}

.img_container {
  width: 100%;
}

.img_container img,
.tWrap img {
  width: 100%;
  object-fit: cover;
  object-position: cover;
}

.anime_img {
  object-fit: cover;
  animation: img_key linear forwards;
  animation-timeline: view(block);
  animation-range: cover 0% cover 20%;
  width: 100%;
}

.img_container.top_img {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

section .tWrap {
  perspective: 1000px;
}

.box {
  animation: noTransformAnim linear forwards normal;
  animation-timeline: view();
  transform: rotateX(40deg);
  opacity: 0;
}

@keyframes noTransformAnim {
  50% { transform: none; opacity: 1; }
  100% { transform: none; opacity: 1; }
}

@keyframes img_key {
  0% { clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%); }
  100% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
}

@keyframes fade-out {
  100% { opacity: 0; scale: 1.5; }
}

/* ------------TEAM CARD---------------- */

#container-team{
  width:100%;
  min-height: 500px;
  /* height:100vh; */
  position: relative;
  background: #e2e2e2;
  overflow: visible;
}
#holder{
  max-width: 700px;
  width: 98%;
  min-height: 360px;
  height:360px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
#image{
  width: 40%;
  height: 100%;
  float: left;
  position: relative;
  z-index: 200;
  background:;
  box-shadow:0px 40px 120px 0px rgba(0,0,0,.52);
  overflow: visible;
}
#right{
  width: 60%;
  height: 100%;
  float: right;
  position: relative;
}
#text{
  width: 60%;
  height: 320px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  background: rgba(255,255,255,.57);
  box-shadow:0px 40px 120px 0px rgba(0,0,0,.52);
}
a{
  display: block;
  text-decoration: none;
  outline: none;
  outline-color: none;

}
/* nav{
  width: 140px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin:0 0 -25px -70px;
  z-index: 1000;
  box-shadow:0px 7px 24px 3px rgba(0,0,0,.52);
  display:none;
} */
#leftBtn{
  width:50%;
  height: 100%;
  float: left;
  color: rgba(255,255,255,.57);
  background:#232323;
  font-size: 25px;
  line-height: 200%;
  text-align: center;
  border-right:1px solid rgba(255,255,255,.57);
  cursor: pointer;
  -webkit-transition:all .3s ease-in;
  -moz-transition:all .3s ease-in;
  -o-transition:all .3s ease-in;
  transition:all .3s ease-in;
}
#rightBtn{
  width:50%;
  height: 100%;
  float: right;
  color: rgba(255,255,255,.57);
  background:#232323;
  font-size: 25px;
  line-height: 200%;
  text-align: center;
  border-left:1px solid rgba(255,255,255,.57);
  cursor: pointer;
  -webkit-transition:all .3s ease-in;
  -moz-transition:all .3s ease-in;
  -o-transition:all .3s ease-in;
  transition:all .3s ease-in;
}
#leftBtn:hover,#rightBtn:hover{
  color: #fff;
}
#nav-cirlces{
  width: 96px;
  height: 20px;
  position: absolute;
  bottom: 8%;
  left: 50%;
  margin-left: -50px;
}
.circle{
  width: 20px;
  height: 100%;
  float: left;
  border-radius:100%;
  border:2px solid #232323;
  margin:0 6px;
  cursor: pointer;
  -webkit-transition:all .75s  cubic-bezier(.86,.3,.07,1);
  -moz-transition:all .75s  cubic-bezier(.86,.3,.07,1);
  -o-transition:all .75s  cubic-bezier(.86,.3,.07,1);
  transition:all .75s  cubic-bezier(.86,.3,.07,1);
}
#centerText{
  width: 100%;
  height:200px;
  position: absolute;
  top: 20%;
  margin-top:-100px;
  padding: 10px 0;
  overflow: visible;
}
.quote{  
  width: 100%;
  height: 150px;
  position: absolute;
  top: 45px;
  left: 0;
  right: -150px;
  bottom: 0;
  margin: auto; 
  font-size: 20px;
  color: #232323;
  padding: 0 25px;
  text-align:center;
  opacity: 0;
  -webkit-transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
  -moz-transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
  -o-transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
  transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
}
p.quote.active{
  right:0;
  opacity: 1;
  background: transparent;
}
p.quote:nth-child(2){
  top: 25px;
}
.active{
  background: #232323;
}
.img{
  width: 100%;
  height: 100%;
  position: absolute;
  right: -100%;
  background-position: center !important;
  background-size:cover;  
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 400;
  -webkit-transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
  -moz-transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
  -o-transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
  transition:all 2s  cubic-bezier(.86,.3,.03,1) , opacity .7s cubic-bezier(.86,.3,.47,1) .3s;
}
.img.active{
  right:0;
  opacity: 1;
  z-index: 500;
}

.zuck{
  background: #fff url(https://raw.githubusercontent.com/hemantparashar/images/quote-cards/markzuck-s.jpg);
}
.musk{
  background: #fff url(https://raw.githubusercontent.com/hemantparashar/images/quote-cards/elonmusk-s.jpg);
}

#bg{
  width: 100%;
  height: 100%;
  position: absolute;
}
.slide{
  width: 100%;
  height: 100%;
  position: absolute;
  /*right: -100%;*/
  opacity: 0; 
  -webkit-transition:all 1.5s ease;
  -moz-transition:all 1.5s ease;
  -o-transition:all 1.5s ease;
  transition:all 1.5s ease; 
}
.slide.active{
  /*right: 0%;*/
  opacity: 1;
}

.zuck-bg{
  background: #fff url(https://raw.githubusercontent.com/hemantparashar/images/quote-cards/markzuck-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.musk-bg{
  background: #fff url(https://raw.githubusercontent.com/hemantparashar/images/quote-cards/elonmusk-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width:700px){

  #centerText p{
    font-size: 20px;
  }
}

@media screen and (max-width:580px){

#holder{
  max-width: 700px;
  width: 100%;
  min-height: 360px;
  height:100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
  margin: auto;
}
.img{
  background-position:top center !important;
}
#image{
  width: 220px;
  height:250px;
  float: left;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -280px;
  margin-left: -110px;
}
#right{
  width: 100%;
  height: 100%;
  float: right;
  position: relative;
  background: blue;
}
#text{
  width: 90%;
  height: 320px;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
#nav-cirlces{
  display: none;
}
#centerText{
  width: 100%;
  height:200px;
  position: absolute;
  top: 30%;
  margin-top:-100px;
  padding: 150px 0!important;
  overflow: visible;
}

}

@media screen and (max-width:450px){
#image{
  width: 200px;
  height:230px;
  float: left;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -100px;
}

#text{
  width: 100%;
  height: 320px;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
#nav-cirlces{
  display: none;
}
#centerText{
  width: 100%;
  height:200px;
  position: absolute;
  top: 30%;
  margin-top:-100px;
  /* padding: 120px 0!important; */
  overflow: visible;
}

}


.logos-slider {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  -webkit-mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
  mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
}

.logos-slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  animation: slide 80s linear infinite;
}

.logos-slider-container img {
  width: 100%;
  max-width: 150px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.logo-slide-card {
  background: #fff;
  padding: 0rem;
  border-radius: .5rem;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0px 3px 8px rgb(61 74 84 / 10%), 0px 3px 12px rgb(61 74 84 / 6%);
}

@font-face {
  font-family: "Pocono";
  src: url('./images/clientLogos/POCONO.TTF') format('ttf');
  font-weight: 400;
  font-style: normal;
}

.text-slider {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.text-slider-container {
  display: flex;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.text-slider-container div {
  padding: 0 20px;
  white-space: nowrap;
  color: white;
  font-size: 1.5em;
  font-family: 'Noto Sans', sans-serif;
  gap: 20px;
}


.Marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background-color: #000; /* Background color */
  padding: 10px 0; /* Padding for better visibility */
  position: relative;
}

.Marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 80s linear infinite; /* Adjust the animation duration as needed */
}

.Marquee-tag {
  display: inline-block;
  padding: 0 20px; /* Spacing between text items */
  font-size: 1.5em;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
  color: #fff;
}

/* Keyframes for marquee animation */
@keyframes marquee {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
}
/* ----------------------MAREQUEE SLIDES START----------------------------- */
.scroll-marquee {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(90deg, transparent, #fff 30%, #fff 70%, transparent);
  /* Ensure animations are paused when 'paused' class is added */
  animation-play-state: running;
}

.scroll-marquee.paused {
  animation-play-state: paused;
}

.scroll-marquee div {
  white-space: nowrap;
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t)/-1);
}

.scroll-marquee div:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t)/-2);
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.scroll-marquee div span {
  display: inline-flex;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 50px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  transition: 0.5s;
  border: 1px solid #f1f1f1;
}

.scroll-marquee div span:hover {
  background: #21cc7a80;
  cursor: pointer;
}
/* ----------------------MARQUEE SLIDES END----------------------------- */

.img-fluid{
  width: 45%;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.3s ease;
}
.client-card{
  transition: all 0.3s ease;
}
.client-card:hover{
  transform: scale(1.1);
  z-index: 1;
} 
@media screen and (max-width: 992px) and (min-width: 300px) {
  .img-fluid {
    width: 30%;
  }
}
.nav-links-footer:hover{
  transition: all 0.3s ease;
}
.nav-links-footer:hover{
  color: #fff !important;
}