
  .testimonial {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #3d5a80; */
    color: #3d5a80;
  }
  .testimonial-slide {
    padding: 0px 20px;
  }
  .testimonial_box-top {
    background-color: #e0fbfc;
    padding: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    box-shadow: 5px 5px 20px rgba(152, 193, 217, 0.493);
  }
  .testimonial_box-icon {
    padding: 10px 0;
  }
  .testimonial_box-icon i {
    font-size: 25px;
    color: #14213d;
  }
  .testimonial_box-text {
    padding: 10px 0;
  }
  .testimonial_box-text p {
    color: #293241;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .testimonial_box-img {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }
  .testimonial_box-img img {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 2px solid #e5e5e5;
  }
  .testimonial_box-name {
    padding-top: 10px;
  }
  .testimonial_box-name h4 {
    font-size: 20px;
    line-height: 25px;
    color: #293241;
    margin-bottom: 0;
  }
  .testimonial_box-job p {
    color: #293241;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 0;
  }